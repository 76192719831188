import React, { useState } from 'react';

const Admin = () => {
  const [file, setFile] = useState(null);
  const [title, setTitle] = useState('');
  const [text, setText] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault(); // Остановить перезагрузку страницы
    const formData = new FormData();

    // Добавляем файл и текстовые данные в formData
    formData.append('photo', file);
    formData.append('title', title);
    formData.append('text', text);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/upload`, {
        method: 'POST',
        body: formData,
      });

      const result = await response.json();
      console.log(result);
    } catch (error) {
      console.error('Ошибка загрузки:', error);
    }
  };

  return (
    <div className='root'>
      <div className='container'>
        <form
          onSubmit={handleSubmit}
          encType='multipart/form-data'>
          <input
            type='file'
            name='photo'
            onChange={(e) => setFile(e.target.files[0])}
            required
          />
          <input
            type='text'
            name='title'
            placeholder='Title'
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
          <textarea
            name='text'
            placeholder='Text'
            value={text}
            onChange={(e) => setText(e.target.value)}
            required></textarea>
          <button type='submit'>Загрузить</button>
        </form>
      </div>
    </div>
  );
};

export default Admin;
