import React from 'react';
import DOMPurify from 'dompurify'; // Для безопасной рабоыт с html разметкой вставленной внутри наших статей в формате json
import { Link } from 'react-router-dom';

const ItemBlock = ({ obj }) => {
  return (
    <Link to={`/item/${obj.id}`}>
      <div className='block'>
        <div className='block-image'>
          <img
            src={obj.image}
            alt={obj.description?.['ru']?.title}
          />
        </div>
        <div className='block-description'>
          <h3
            className='title'
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(obj.description?.['ru']?.title),
            }}></h3>
          <p
            className='text'
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(obj.description?.['ru']?.text),
            }}></p>
          <hr />
          <p className='price'>{obj.price} ¥</p>
        </div>
      </div>
    </Link>
  );
};

export default ItemBlock;
