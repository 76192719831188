import React from 'react';
import { Link } from 'react-router-dom';
import { setCategory } from '../../redux/slices/fetchSlice';
import { useDispatch, useSelector } from 'react-redux';

const Category = () => {
  const { category } = useSelector((state) => state.fetch);
  const headerList = [
    { name: 'Связь', link: '/', category: 'link' },
    { name: 'Туры', link: '/', category: 'tur' },
    { name: 'Рестораны', link: '/', category: 'restorants' },
    { name: 'Транспорт', link: '/', category: 'tansport' },
    { name: 'Отели', link: '/', category: 'hotel' },
    { name: 'Шопинг', link: '/', category: 'shoping' },
    { name: 'О городе', link: '/', category: 'city' },
  ];
  const dispatch = useDispatch();

  const onClickCategory = (cat) => {
    dispatch(setCategory(cat));
    console.log();
  };
  return (
    <div className='category-container'>
      <div className='category-block'>
        <div className='category'>
          {headerList.map((obj, i) => (
            <Link
              className={obj.category === category ? 'active' : ''}
              onClick={() => onClickCategory(obj.category)}
              to={obj.link}
              key={i}>
              {obj.name}
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Category;
