import React from 'react';
import DOMPurify from 'dompurify'; // Для безопасной рабоыт с html разметкой вставленной внутри наших статей в формате json
import { useParams } from 'react-router-dom';
import { fetchItem } from '../redux/slices/fetchSlice';
import { useDispatch, useSelector } from 'react-redux';

const FullItem = () => {
  const { id } = useParams(); // Здесь мы деструктурируем id напрямую
  /* const navigate = useNavigate(); */
  const dispatch = useDispatch();
  const item = useSelector((state) => state.fetch.item);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        if (id) {
          dispatch(fetchItem({ id }));
        }
      } catch (error) {
        console.error('Ошибка при запросе данных:', error);
      }
    };

    fetchData();
  }, [id, dispatch]);

  if (!item) {
    return <p>Идет загрузка...</p>;
  }

  return (
    <div className='container'>
      <h1>{item.id}</h1>
      <h1>{item.description.ru.title}</h1>
      <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.description.ru.text) }}></p>
      <img
        className='pizza-block__image'
        src={
          item.image ||
          'https://dodopizza-a.akamaihd.net/static/Img/Products/Pizza/ru-RU/b750f576-4a83-48e6-a283-5a8efb68c35d.jpg'
        }
        alt='Фото пиццы'
      />
      <p>{item.price}</p>
    </div>
  );
};

export default FullItem;
