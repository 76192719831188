import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const fetchItems = createAsyncThunk(
    'items/fetchItems',
    async ({ queryString }) => {
        const { data } = await axios.get(`${API_URL}/items?${queryString}`);

        console.log('Первый запрос пошел', data);
        return data
    },
)

/* export const fetchItem = createAsyncThunk(
    'item/fetchItem',
    async ({ params }) => {
        const { data } = await axios.get(`http://localhost:4000/item/` + params.id);
        console.log('Второй запрос пошел', data);
        return data
    },
) */

export const fetchItem = createAsyncThunk(
    'item/fetchItem',
    async ({ id }) => {  // Передаем только id
        const { data } = await axios.get(`${API_URL}/item/${id}`); // правильный URL
        return data;
    }
);


const initialState = {
    category: "tur",
    items: [],
    item: null,
}

const fetchSlice = createSlice({
    name: 'fetch',
    initialState,
    reducers: {
        setCategory(state, action) {
            state.category = action.payload
        },
        setItemId(state, action) {
            state.item = action.payload
        }
    },
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder.addCase(fetchItems.fulfilled, (state, action) => {
            state.items = action.payload;
            state.status = "fulfilled";
        });
        builder.addCase(fetchItems.pending, (state) => {
            state.items = [];
            state.status = "pending";
        });
        builder.addCase(fetchItems.rejected, (state) => {
            state.items = [];
            state.status = "rejected";
        });
        //----2
        builder.addCase(fetchItem.fulfilled, (state, action) => {

            console.log('Данные получены:', action.payload);  // Логируем полученные данные
            state.item = action.payload;
            state.status = "fulfilled";
        });
        builder.addCase(fetchItem.pending, (state) => {
            state.item = null;
            state.status = "pending";
        });
        builder.addCase(fetchItem.rejected, (state) => {
            state.item = null;
            state.status = "rejected";
        });
    },
})

// ----- Здесь все экспортируемые actions которые мы используем, чтобы изменить states
export const { setCategory, setItemId } = fetchSlice.actions;

// ----- Это для того, чтобы связать данный файл с store.js -----
export default fetchSlice.reducer;