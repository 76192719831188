import React from 'react';
/* import { replace } from 'react-router-dom'; */
/* import VerifyCodeForm from './components/verification'; */

const Anketa = () => {
  const [firstName, setFirstName] = React.useState(null);
  const [secondName, setSecondName] = React.useState('');
  const [data, setData] = React.useState('');
  const [hotels, setHotels] = React.useState(''); // Стейт для выбранного отеля
  const [tel, setTel] = React.useState('');
  const [numberOfFlat, setNumberOfFlat] = React.useState(null);
  const [gradesA, setGradesA] = React.useState({
    grade1: null,
    grade2: null,
    grade3: null,
    grade4: null,
  });
  const [gradesB, setGradesB] = React.useState({
    grade1: null,
    grade2: null,
    grade3: null,
    grade4: null,
  });
  const [gradesC, setGradesC] = React.useState({
    grade1: null,
    grade2: null,
    grade3: null,
    grade4: null,
  });
  /*   const [showVerification, setShowVerification] = React.useState(false); // Для отображения формы верификации */

  const handleSubmit = async (e) => {
    e.preventDefault(); // Остановить перезагрузку страницы
    const formData = new FormData();

    formData.append('FirstName', firstName);
    formData.append('SecondName', secondName);
    formData.append('data', data);
    formData.append('hotels', hotels);
    formData.append('tel', tel);
    formData.append('numberOfFlat', numberOfFlat);
    //-----block-1
    formData.append('grade1A', gradesA.grade1);
    formData.append('grade2A', gradesA.grade2);
    formData.append('grade3A', gradesA.grade3);
    formData.append('grade4A', gradesA.grade4);
    //-----block-2
    formData.append('grade1B', gradesB.grade1);
    formData.append('grade2B', gradesB.grade2);
    formData.append('grade3B', gradesB.grade3);
    formData.append('grade4B', gradesB.grade4);
    //-----block-3
    formData.append('grade1C', gradesC.grade1);
    formData.append('grade2C', gradesC.grade2);
    formData.append('grade3C', gradesC.grade3);
    formData.append('grade4C', gradesC.grade4);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/anketa`, {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        alert('Отзыв успешно сохранен');
        window.location.href = '/'; // Перемещает пользователя на главную страницу
      } else {
        console.error('Ошибка при сохранении данных');
      }
    } catch (error) {
      console.error('Ошибка загрузки:', error);
    }
  };

  const gradeList = [
    {
      topTitle: '1. Оцените Обслуживания',
      gradeListTotal: [
        {
          title: 'Организация экскурсии в целом',
          gradeNumber: 'grade1',
          setGradeNumber: setGradesA,
          grades: gradesA,
        },
        {
          title: 'Соотношение цена-качество',
          gradeNumber: 'grade2',
          setGradeNumber: setGradesA,
          grades: gradesA,
        },
        {
          title: 'Количество остановок',
          gradeNumber: 'grade3',
          setGradeNumber: setGradesA,
          grades: gradesA,
        },
        {
          title: 'Досточность времени для просмотра экскурсии',
          gradeNumber: 'grade4',
          setGradeNumber: setGradesA,
          grades: gradesA,
        },
      ],
    },
    {
      topTitle: '2. Оценка сервиса транспортных услуг',
      gradeListTotal: [
        {
          title: 'Комфорт и чистота автобуса',
          gradeNumber: 'grade1',
          setGradeNumber: setGradesB,
          grades: gradesB,
        },
        {
          title: 'Внешний вид водителя',
          gradeNumber: 'grade2',
          setGradeNumber: setGradesB,
          grades: gradesB,
        },
        {
          title: 'Безопасность вождения',
          gradeNumber: 'grade3',
          setGradeNumber: setGradesB,
          grades: gradesB,
        },
        {
          title: 'Своевременность подачи транспорта',
          gradeNumber: 'grade4',
          setGradeNumber: setGradesB,
          grades: gradesB,
        },
      ],
    },
    {
      topTitle: '3. Оценка качества работы экскурсионного гида',
      gradeListTotal: [
        {
          title: 'Компетентность и профессионализм',
          gradeNumber: 'grade1',
          setGradeNumber: setGradesC,
          grades: gradesC,
        },
        {
          title: 'Представление информация',
          gradeNumber: 'grade2',
          setGradeNumber: setGradesC,
          grades: gradesC,
        },
        {
          title: 'Внешний вид',
          gradeNumber: 'grade3',
          setGradeNumber: setGradesC,
          grades: gradesC,
        },
      ],
    },
  ];

  const handleGradeChange = (setGradeNumber, gradeKey, value) => {
    setGradeNumber((prevGrades) => ({
      ...prevGrades,
      [gradeKey]: value,
    }));
  };

  const gradeRange = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  return (
    <div className='root'>
      <div className='containerAnketa'>
        <div className='anketa'>
          <div>
            <h1>Экскурсионная анкета</h1>
            <hr />
            <p className='dearGuests'>
              <strong>Дорогие гости! </strong>С целью улучшения качества обслуживания нашей
              компанией, предлагаем Вам ответить на несколько вопросов и поделиться своими
              впечатлениями об организации экскурсии. Отправив анкету, вы даёте согалсие на сбор и
              обработку данных.
            </p>
            <hr />
          </div>
          <form
            className='formaShmorma'
            onSubmit={handleSubmit}
            encType='multipart/form-data'>
            <div className='blockTop'>
              <h2>
                Укажите пожалуйста ваши контактные данные, после чего вам на телеграм придет 4
                значный код, который нужно будет ввести в целях подтверждения личности.
              </h2>
              <div className='miniBlock'>
                <label
                  htmlFor='text'
                  id='text'>
                  Имя:
                </label>
                <input
                  type='text'
                  name='FirstName'
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                />
              </div>
              <div className='miniBlock'>
                <label
                  htmlFor='text'
                  id='text'>
                  Фамилия:
                </label>
                <input
                  type='text'
                  name='SecondName'
                  onChange={(e) => setSecondName(e.target.value)}
                  required
                />
              </div>
              <div className='miniBlock'>
                <label
                  htmlFor='data'
                  id='data'>
                  Дата фашей экскурсии:
                </label>
                <input
                  type='date'
                  name='data'
                  onChange={(e) => setData(e.target.value)}
                  required
                />
              </div>
              <div className='miniBlock'>
                <label htmlFor='hotels'>В каком отеле вы остановились:</label>
                <select
                  id='hotels'
                  name='hotels'
                  value={hotels} // Привязываем значение к стейту
                  onChange={(e) => setHotels(e.target.value)} // Изменение состояния при выборе
                  required>
                  <option value='Sunshine Resort Intime Sanya'>Sunshine Resort Intime Sanya</option>
                  <option value='Sanya Dadonghai Seaview Hotel'>
                    Sanya Dadonghai Seaview Hotel
                  </option>
                  <option value='Ascott Dadonghai Bay Sanya'>Ascott Dadonghai Bay Sanya</option>
                  <option value='Sanya Junji Seaview Hotel'>Sanya Junji Seaview Hotel</option>
                </select>
              </div>
              <div className='miniBlock'>
                <label htmlFor='numberOfFlat'>Номер в отеле:</label>
                <input
                  type='text'
                  id='numberOfFlat'
                  name='numberOfFlat'
                  onChange={(e) => setNumberOfFlat(e.target.value)}
                  required
                />
              </div>
              <div className='miniBlock'>
                <label htmlFor='tel'>Ваш номер телефона:</label>
                <input
                  type='tel'
                  id='tel'
                  name='tel'
                  onChange={(e) => setTel(e.target.value)}
                  required
                />
              </div>
            </div>
            <h2>
              Оцените, насколько Вы удовлетворены качеством обслуживания ЭДЕН ТРЕВЕЛ во время вашего
              отдыха по 10 бальной шкале, где 1 - это ужасно, а 10 - это отлично.
            </h2>
            {gradeList.map((obj, i) => (
              <div
                key={i}
                className='blockTop'>
                <hr />
                <p className='title'>{obj.topTitle}</p>
                {obj.gradeListTotal.map((objTotal, j) => (
                  <div
                    key={j}
                    className='miniBlock'>
                    <label htmlFor={objTotal.gradeNumber}>{objTotal.title}</label>
                    <select
                      id={objTotal.gradeNumber}
                      name={objTotal.gradeNumber}
                      value={objTotal.grades[objTotal.gradeNumber]}
                      onChange={(e) =>
                        handleGradeChange(
                          objTotal.setGradeNumber,
                          objTotal.gradeNumber,
                          e.target.value,
                        )
                      }
                      required>
                      {gradeRange.map((num) => (
                        <option
                          key={num}
                          value={num}>
                          {num}
                        </option>
                      ))}
                    </select>
                  </div>
                ))}
              </div>
            ))}
            <hr />
            <button type='submit'>Загрузить</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Anketa;
