import React from 'react';
import qs from 'qs';
import ItemBlock from './components/itemBlock';
import { fetchItems, setCategory } from '../redux/slices/fetchSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Category from './components/category';

const Home = () => {
  const isSearch = React.useRef(false);
  const isMounted = React.useRef(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { category } = useSelector((state) => state.fetch);
  const { items } = useSelector((state) => state.fetch);

  const queryString = React.useMemo(() => {
    const params = new URLSearchParams();
    if (category) params.append('category', category);
    return params.toString();
  }, [category]);

  React.useEffect(() => {
    const fetchData = async () => {
      if (!isSearch.current) {
        try {
          // Выполниться в случаи безошибочности кода
          // Запрос всех пицц даже когда фильтруем
          dispatch(fetchItems({ queryString }));
        } catch (error) {
          // Выполниться в случаи ошибки. хотя нужно отметить что мы уже обрабатываем ошибки в файле fetchSlice через екстраредюсеры
          console.error('Ошибка при запросе данных:', error);
        } finally {
          // Выполниться в любом случаи, ошибка не ошибка
        }
      }
      isSearch.current = false;
    };

    fetchData();
  }, [queryString, dispatch]);

  //-----Это чтобы при выбора категории, в нашей строке поиска отображалось что мы выбрали а не просто локахост3000
  React.useEffect(() => {
    if (isMounted.current) {
      navigate(`?${queryString}`);
    }
    isMounted.current = true;
  }, [queryString, navigate]);

  // Эффект для восстановления фильтров из URL после того как мы обновляем уже отфильтрованную страницу, посел чего выбранные ранее фильтры сохраняются
  React.useEffect(() => {
    if (window.location.search) {
      isSearch.current = true;
      const params = qs.parse(window.location.search.substring(1));

      dispatch(setCategory(params.category));
      isSearch.current = false;
    }
  }, [dispatch, queryString]);
  //-----Возвращаем компонент
  return (
    <div className='root'>
      <Category />
      <section className='container'>
        {Array.isArray(items) &&
          items.map((obj, i) => (
            <ItemBlock
              key={i}
              obj={obj}
            />
          ))}
      </section>
    </div>
  );
};

export default Home;
