
import { Route, Routes } from 'react-router-dom';
import Header from './pages/components/header';
import Home from './pages/Home';
import './styles/App.scss';
import FullItem from './pages/FullItem';
import Admin from './pages/Admin';
import Anketa from './pages/Anketa';

function App() {

  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path='/' element={<Home />}></Route>
        <Route path="/item/:id" element={<FullItem />}></Route>
        <Route path='/admin' element={<Admin />}></Route>
        <Route path='/anketa' element={<Anketa />}></Route>
        <Route
          path='*'
          element={"Ошибка"}></Route>
      </Routes>
    </div>
  );
}

export default App;
